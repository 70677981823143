import {Env} from './env.enum';
import packageInfo from '../../package.json';

export const environment = {
  env: Env.Production,
  API_BASE_URL: 'https://api.sharea.bike',
  MEDIA_HOST: 'https://api.sharea.bike',
  loginUrl: '/api/auth',
  tokenRefreshUrl: '/api/auth/refresh',
  version: packageInfo.version,
};
